import styled from '@emotion/styled';
import tw from 'twin.macro';

export const PageHeader = styled.h1`
  ${tw`px-4 mb-4 text-3xl lg:text-5xl xl:px-0`};
`;

export const PageSubHeader = styled.h2`
  ${tw`px-4 mb-12 text-xl font-semibold xl:px-0`};
`;
