import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Container } from '../../components/container';
import { Layout } from '../../components/layout';
import { SEO } from '../../components/seo';
import { Service } from '../../components/service';
import { TwoColumnText } from '../../components/two-column-text/TwoColumnText';
import { PageHeader } from '../../styles';
import { splitAndChunkText } from '../../utils/splitAndChunkText';

export const ServicesPage: React.FC = () => {
  const {
    servicesPage,
    services,
  } = useStaticQuery<GatsbyTypes.ServicesPageQuery>(graphql`
    query ServicesPage {
      servicesPage: contentfulPage(slug: { eq: "services" }) {
        id
        headerText
        description {
          description
        }
        copy {
          copy
        }
        keywords
      }
      services: allContentfulService(sort: { order: ASC, fields: order }) {
        edges {
          node {
            serviceName
            cost
            image {
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  const paragraphChunks = splitAndChunkText({ text: servicesPage?.copy?.copy });

  return (
    <Layout>
      <SEO
        title="Services"
        description={servicesPage?.description?.description}
        keywords={servicesPage?.keywords}
      />
      <div className="py-10 xl:py-20">
        <Container>
          <PageHeader>{servicesPage?.headerText}</PageHeader>
          <div className="px-4 text-xl leading-relaxed xl:px-0 font-athiti">
            <TwoColumnText chunks={paragraphChunks} />
          </div>
          <div className="lg:flex lg:items-center lg:justify-between">
            {services.edges.map(({ node }) => (
              <Service
                serviceName={node.serviceName}
                cost={node.cost}
                image={node?.image?.fluid}
              />
            ))}
          </div>
        </Container>
      </div>
    </Layout>
  );
};
