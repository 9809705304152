import React from 'react';

type TwoColumnTextProps = {
  chunks: string[][];
};

export const TwoColumnText: React.FC<TwoColumnTextProps> = ({ chunks }) => (
  <div className="lg:grid lg:grid-flow-col lg:grid-rows-1 lg:gap-8 lg:mb-12 lg:text-justify">
    {chunks.map((chunk, idx) => (
      <div key={`chunk-${idx}`}>
        {chunk.map((text, idx) => (
          <p key={`paragraph-${idx}`} className="mb-4">
            {text}
          </p>
        ))}
      </div>
    ))}
  </div>
);
