import React from 'react';

import Image, { FluidObject } from 'gatsby-image';
import { ButtonLink } from '../button-link';
import { useSiteMetadata } from '../../hooks/useSiteMetadata';

type ServiceProps = {
  image?: FluidObject;
  serviceName?: string;
  cost?: number;
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const Service: React.FC<ServiceProps> = ({
  image,
  serviceName,
  cost,
}) => {
  const { bookingLink } = useSiteMetadata();

  return (
    <div className="border border-gray-400 md:mx-auto md:mb-4 lg:mb-0 lg:mx-0 md:max-w-md">
      {image && <Image className="max-h-48" fluid={image} />}
      <div className="px-8 py-8 text-center">
        <h1 className="mb-8 text-lg">
          {serviceName} | {cost && formatter.format(cost)}
        </h1>
        {bookingLink && (
          <ButtonLink title="Book Now" to={bookingLink} external />
        )}
      </div>
    </div>
  );
};
