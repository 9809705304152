import { chunk } from 'lodash';

type SplitAndChunkTextProps = {
  text?: string;
  chunkSize?: number;
};

export const splitAndChunkText = ({
  text = '',
  chunkSize = 2,
}: SplitAndChunkTextProps): string[][] => {
  const splits = (text.split('\n') || []) as string[];
  return chunk(splits, Math.ceil(splits.length / chunkSize));
};
